<template>
  <div class="container">
    <div class="row mb-3">
      <div class="col-auto ms-auto">
        <router-link class="btn btn-success" to="/roles/create">
          <i class="far fa-plus me-2"></i>Add New Role
        </router-link>
      </div>
    </div>
    <loading v-if="roles.length == 0"></loading>
    <div class="row" v-if="roles.length > 0">
      <div class="col-4 mb-4" v-for="r in roles" :key="r.id">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <h5 class="fw-bold text-capitalize">{{ r.name }}</h5>
            <ul>
              <li
                class="text-capitalize"
                v-for="p in r.permissions"
                :key="p.id"
              >
                {{ p.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      roles: [],
    };
  },
  methods: {
    fetchRoles() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/roles")
        .then(({ data }) => {
          this.roles = data;
        });
    },
  },
  mounted() {
    this.fetchRoles();
  },
  components: {
    Loading,
  },
};
</script>

<style>
</style>